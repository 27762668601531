import React from 'react';
import Layout from '../components/global/layout/layout';
import AirtableForm from '../components/global/airtable-form/airtable-form';

const CourseEnrolmentPage = () => (
  <Layout>
    <AirtableForm title="Course enrolment form" formId="shrkqWX5Yq7WbhK2a" />
  </Layout>
);

export default CourseEnrolmentPage;
